body{
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #fefefe;
  }
.home-container{
    display: flex;
}
button{
    color: #fefefe;
}
.left-menu-container{
    background-color: #181818;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10vw;
    min-width: 10vw;
    height: 100vh;
    min-height: 100%;
    color: #fefefe;
}
.left-menu-container a{
    color: #fefefe;
    font-size: x-large;
    margin: 1rem;
}
.left-menu-container button{
    margin: 1rem;
    background-color: purple;
    color: white;
    font-size: x-large;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    width: 80%;

}
.right-container{
    min-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
.dashboard-container {
    display: flex;

}

.right-container h1{
    margin-bottom: 4rem;
}
.control-panel{
    color: #133b5f
}
select option {
    background-color: yellow;
}

/* .studio-links li{
list-style-type: disc;
margin-left: 1rem;
} */

.canvasParent{
    position: relative;
    border: 1px solid black;
    width: 90%;
    height: 90%;
}

.optionDiv{
    position: relative;
    z-index: 2;
}

.canvas{
    position: absolute;
    z-index: 1;
    width: 100% !important;
    height: 100% !important;
}
.loadingDiv{
    position: absolute; 
    margin: 0 auto;
    z-index: 2;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
}

.list-container{
    margin-top: 0;
}
hr{
    width: 50%;
    left: 0;
}

.dashboardBtn{
    background-color: purple;
    color: white;
    font-size: x-large;
    border-radius: 10px;
    height: 3rem;
    border: 0;
    cursor: pointer;
}

.link-container{
    border-left: 0px;
    border-top: 0px;
    border-bottom: 1px;
    border-right: 0px;
    border-color: #133b5f;
    border-style: solid;
}
.links-container button{
    height: 30px;
    margin-left: 1rem;
    font-size: 1rem;
}
 a{
    color: #fefefe;
    font-size: x-large;
}

@media only screen and (max-width: 900px) {
    
    .left-menu-container{
        display: none;
    }
    .right-container{
        width: 100vw;
        height: 100vh;
    }
    .control-panel-container{
        max-width: 370px;
        z-index: 5;
    }
    .control-panel-container button{
        width: 1rem;
        font-size: small;

  }
  .login-left-container{
    display: none
  }
  .login-right-container{
    width: 100vw;
  }

}
.dashboard-container{
    height: 100%;
    width: 100%;
}
.upload-status-message{
    font-size: x-large;
    color: red;
}
.links-container{
    height: 100vh;
    overflow: scroll;
    overflow-y:scroll;
    padding-left: 5rem;
    min-width: 60%;
    width: 100%;
}
.upload-container{
    margin-bottom: 1rem;
    max-width: 40%;

}
.upload-container input::file-selector-button{
    background-color: purple;
    color: white;
    font-size: large;
    border-radius: 10px;
    height: 3rem;
    border: 0;
    cursor: pointer;
}
.upload-container input{
    margin: 1rem 0;
    width: 80%;
}

.upload-container textarea{
    background-color: #181818;
    color: #fefefe;
    margin-top: 0.5rem;
}
.sidebar-links-container{
    display: flex;
    flex-direction: column;
}
.faq-container{
    height: 90%;
    width: 90%;
    background-color: #181818;
    font-size: 1.2rem;
    border-radius: 20px;

}

/* 
@tailwind base;
@tailwind components;
@tailwind utilities; */