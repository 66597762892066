.login-container{
    height: 100vh;
    display: flex;
    font-size:large;
    color: #e6e6e6;
}
.login-left-container{
    width: 55vw;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.left-image-container{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
}
.left-image-container img{
    height: 100%;
    object-fit: contain;
    border-radius: 2rem;
    margin-bottom: 2rem;
    
    
}
.left-text-container{
    height: 50%;
    width: 95%;
    display: flex;
    flex-direction: column;
}
.left-text-container h2{
    margin-bottom: 0;
    margin-top: 2rem;
    padding-bottom: 1rem;
    margin-left: 0.5rem;

}.left-text-container h3{
font-size: 1.3rem;
margin-left: 0.5rem;


}
.left-text-container p{
margin: 0.5rem;
font-size: 1.1rem;
}
.login-right-container{
    width: 45vw;
    min-height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #181818;
    border-radius: 20px;
    margin-right: 5rem;
    font-size: x-large;

}
.input-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.input-container input{
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    border-radius: 10px;
    border: 1px solid gray;
    margin: 1rem 1rem 0 0;
    font-size: large;
}
.login-button-container{
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
.login-button{
    background-color: purple;
    color: white;
    font-size: x-large;
    width: 100%;
    border-radius: 10px;
    height: 3rem;
    border: 0;
    cursor: pointer;

}

.login-text-paragraph{
    font-size: 1rem;
}

.register-link{
    text-decoration: underline;
    margin-top: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
}  
.error-div{
    padding: 1rem;
    color: red;
    font-weight: bolder;
}
.forgot-password-link{
    margin-top: 1rem;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 2rem;
}