.control-panel-container{
    position: relative;
    display: flex;
    justify-content: center;
    width: 600px;
}

.control-panel-container > * {
    margin-right: 10px;
}

.control-panel-container input{
    width: 0;
    margin-right: 0;
}

.prev-button {
    margin-right: 10px;
}

.control-panel-container label{
    overflow: hidden;
}

.control-panel-container button{
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 0.5rem;
    height: 80%;
    cursor: pointer;
    width: 6rem;
}

.toggle-button {
    appearance: none;
  }

  .toggle-button + label {
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: purple;
    position: relative;
    cursor: pointer;
  }

  .toggle-button + label::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border-right: 4px solid white;
    border-left: 4px solid white;
    border-top: 0;
    border-bottom: 0;
  }

  .toggle-button:checked + label::before {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 14px solid white;
    border-right: 0;
  }

  .reset-button {
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: purple;
    position: relative;
    cursor: pointer;
}

.reset-button::before {
    content: "↻";
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .prev-button {
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: purple;
    position: relative;
    cursor: pointer;
}

.prev-button::before {
    content: "⏵";
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 25px;
    color: white;
    transform: translate(-50%, -50%) scaleX(-1);
}

.next-button {
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: purple;
    position: relative;
    cursor: pointer;
}

.next-button::before {
    content: "⏵";
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 25px;
    color: white;
    transform: translate(-50%, -50%)
}
